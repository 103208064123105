import Mgoh1 from "../assets/products/mgoh1.jpeg";
import Mgoh2 from "../assets/products/mgoh2.jpeg";
import feedgrade from "../assets/products/feedgrade.png";
import hydratedlime from "../assets/products/hydratedlime.jpeg";
import limestone from "../assets/products/limestone.jpeg";
import quicklime from "../assets/products/quicklime.jpeg";
import sulphur from "../assets/products/sulphur.jpeg";

const productGallery = [
  Mgoh1,
  Mgoh2,
  limestone,
  quicklime,
  hydratedlime,
  feedgrade,
  sulphur,
];

export default productGallery;
